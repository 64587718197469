import React from "react";
import Icons from "src/components/Icons";
import CTAReact from "src/components/CTA/CTAReact";

const Pricing2 = () => {
  return (
    <section className="pricing2 dark">
      <div className="boxed">
        <h3 className="sunset text-clip">
          O caminho para o sucesso na sua carreira
        </h3>

        <div className="panel">
          <div className="info">
            <p>
              Curso React & Node JS{" "}
              <strike className="old-price">R$ 1997,00</strike>
            </p>
            <p>
              Desconto somente hoje com o cupom "
              <strong className="coupom">QUINHENTAO</strong>".
            </p>
            <p>Preço Final:</p>
            <p>
              <strong className="price">R$ 1497.00</strong> à vista ou 12 x
              de&nbsp;
              <strong className="installments">R$ 145,83*</strong>.
            </p>

            <CTAReact />
          </div>
          <div className="illustration">
            <p>
              <strong>BONUS</strong>
            </p>
            <p>Curso de JavaScript</p>
            <p>E-book JavaScript: As Novidades do ES6</p>
            <p>Canal do Discord</p>
            <p>Bate-papo quinzenal em vídeo com os alunos</p>
            <Icons id="illustration-data" />
          </div>
        </div>
      </div>
    </section>
  );
};

export default Pricing2;

import React, { useState, useEffect } from "react";
import { secondsToTime } from "src/helpers/datetime";

const TimerDiscount = ({ startsAt, endsAt, current = new Date() }) => {
  const [timeLeft, setTimeLeft] = useState(null);
  const now = new Date(current);
  const start = new Date(startsAt);
  const end = new Date(endsAt);
  end.setDate(now.getDate());

  useEffect(() => {
    const timer = setTimeout(() => {
      const timeLeft = (end.getTime() - now.getTime()) / 1000;
      setTimeLeft(timeLeft);
    }, 1000);

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [timeLeft, now, end]);

  if (!timeLeft || start.getTime() > now.getTime()) return null;

  return (
    <div className="timer-discount">
      <div className="timer">{secondsToTime(timeLeft)}</div>
      <div className="info">
        <h3>DESCONTO DE 500 REAIS</h3>
        <small>Usando o cupom: "QUINHENTAO"</small>
      </div>
    </div>
  );
};

export default TimerDiscount;

// import React, { useEffect } from "react";
// import { redirectTo } from "src/helpers/route";
// const Redirect = () => {
//   useEffect(() => {
//     const timer = setTimeout(() => {
//       redirectTo("/links");
//     }, 1000);
//     return () => {
//       clearTimeout(timer);
//     };
//   }, []);

//   return <p>Redirecionando...</p>;
// };
// export default Redirect;

// /*

import React from "react";
import Youtube from "emersonbroga-youtube-embed";
import Icons from "src/components/Icons";
import CTAReact from "src/components/CTA/CTAReact";
import SEO from "src/components/SEO";
import TextSwitch from "src/components/TextSwitch";
import TimerDiscount from "src/components/TimerDiscount";
import FAQ2 from "src/sections/FAQ2";
import Pricing2 from "src/sections/Pricing2";
import Testimonials from "src/sections/Testimonials";
import About from "src/sections/About";
import Footer from "src/sections/Footer";

import { BASE_URL, NAME } from "src/helpers/config";
import faqReact from "src/data/faq-react.json";

import "./style.scss";

const Curso = () => {
  // const videoId = "4f3ANmq64E8";// milzao
  const videoId = "edeUYRObslo"; // quinhentao

  const seoProps = {
    title: `Curso React e Node JS - Inscrições abertas - ${NAME}`,
    canonical: `${BASE_URL}/react/`,
    description: `Sua grande chance de se dedicar, evoluir e estar preparado para as melhores vagas do mercado.`,
    image: `https://i3.ytimg.com/vi/${videoId}/maxresdefault.jpg`,
  };

  return (
    <div className="react-course ">
      <SEO {...seoProps} />
      <section className="presentation dark">
        <div className="header boxed">
          <div className="logo">
            <Icons id="broga" />
          </div>
          <TimerDiscount
            startsAt={new Date("2021-09-18T08:00:00")}
            endsAt={new Date("2021-09-20T23:59:59")}
          />
        </div>

        <div className="intro boxed">
          <h1 className="sunset text-clip">
            <b>
              Aprenda
              <TextSwitch
                text={["JavaScript", "NodeJS", "React"]}
                time="2000"
              />
            </b>
            <span>de uma vez por todas</span>
          </h1>
          <div className="promo">
            <div className="text">
              <p>
                Quer trabalhar para qualquer lugar do mundo e ganhar os ótimos
                salários que a profissão de programador pode proporcionar?
              </p>
              <p>
                Chegou a hora de você se comprometer e se dedicar a sua
                carreira.
              </p>
              <div className="promo-pricing">
                <h3 className="sunset text-clip">Curso React e Node JS</h3>
                <p className="full-price">
                  <strike>R$ 1997,00</strike>
                </p>
                <p className="coupom">
                  Desconto somente hoje com o cupom "<strong>QUINHENTAO</strong>
                  ".
                </p>
                <p className="installments">
                  <span>12x</span> de <strong>145,83</strong>*.
                </p>
                <p className="downpayment">
                  <strong>1497.00</strong> à vista
                </p>
              </div>
            </div>

            <div className="video">
              <Youtube videoId={videoId} />
              <p>
                Aula extra da Maratona JS 3 e Tudo sobre o Curso de React e
                NodeJS
              </p>
              <p>
                <strong>
                  Coloque o video em tela cheia e assista até o final
                </strong>
              </p>
            </div>
            <div className="image">
              <img
                load="lazy"
                draggable="false"
                src="/images/course-js-node-react.png"
                alt="Curso de React e Node JS do Emerson Brôga"
              />
            </div>
          </div>
        </div>
        <CTAReact />
      </section>

      <section className="course-details">
        <div className="boxed">
          <h3 className="sunset text-clip">O que tem no Curso?</h3>
          <div className="video">
            <Youtube videoId="rmPwW95K1rk" />
          </div>
        </div>
      </section>

      <Testimonials />

      <Pricing2 />

      <FAQ2 data={faqReact.data} />

      <About />
      <div className="more-details">
        <small>* Tarifa de 2,49% a.m.</small>
      </div>
      <Footer />
    </div>
  );
};
export default Curso;

import React from "react";
import Purchase from "src/components/CTA/Purchase";

// import { CHECKOUT_REACT_997, CHECKOUT_REACT_1497 } from "src/helpers/hotmart";
import { CHECKOUT_REACT_1497 } from "src/helpers/hotmart";

const CTAReact = () => {
  const ctaProps = {
    title: "Quero entrar na Nova Turma",
    secondaryTitle: "Dados Pessoais:",
    submitLabel: "Proximo passo",
    checkout: CHECKOUT_REACT_1497,
    isModal: false,
  };

  return <Purchase {...ctaProps} />;
};
export default CTAReact;

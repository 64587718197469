import React, { useState, useEffect } from "react";

const TextSwitch = ({ text, time = 2000 }) => {
  const [current, setCurrent] = useState(null);
  useEffect(() => {
    if (!text || !text.length) return;

    if (!current && current !== text[0]) setCurrent(text[0]);

    const timer = setTimeout(() => {
      const nextIndex = text.indexOf(current) + 1;
      const index = nextIndex >= text.length ? 0 : nextIndex;
      setCurrent(text[index]);
    }, time);

    return () => {
      if (timer) clearTimeout(timer);
    };
  }, [text, time, current]);

  return <span className="text-switch">{current}</span>;
};

export default TextSwitch;
